// @font-face {
//   font-family: "Gordita";
//   src: url("fonts/gordita/Gordita-Light.woff2") format("woff2");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "catacumba_proregular";
//   src: url("fonts/catacumbapro-regular-webfont.woff2") format("woff2"),
//     url("fonts/catacumbapro-regular-webfont.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Didot";
//   src: url("fonts/Didot.woff2") format("woff2"),
//     url("fonts/Didot.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }
html,
body {
  font-family: "Work Sans", sans-serif;
  // @apply text-text bg-offwhite;
  @apply text-text bg-white;
}

.catacumba {
  font-family: "catacumba_proregular";
}
.didot {
  font-family: "Didot";
}
.text-block {
  h1,
  h2,
  h3 {
    font-family: "Didot";
    margin-bottom: 20px;
    color: #475374;
  }
}
.work-sans {
  font-family: "Work Sans", sans-serif;
}
.wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: calc(1380px + 40px);
  padding: 0 20px;
  @media (min-width: 1000px) {
    padding: 0 60px;
    max-width: calc(1400px + 120px);
  }
}
.wrapper-insert {
  width: 100%;
  margin: 0 auto;
  max-width: calc(1280px + 40px);
  padding: 0 20px;
  @media (min-width: 1000px) {
    padding: 0 60px;
    max-width: calc(1160px + 120px);
  }
}
.text-col {
  ul {
    list-style: initial;
    padding-left: 20px;
    margin-bottom: 1.6em;
    line-height: 1.64;
  }
}
.mega-nav {
  .wrapper-insert {
    width: 100%;
    margin: 0 auto;
    max-width: calc(1280px + 40px);
    padding: 0 20px;
    @media (min-width: 1000px) {
      padding: 0 60px;
      max-width: calc(1190px + 120px);
    }
  }
}
@mixin letter-spacing($spacing) {
  letter-spacing: calc(-1em * 0.001 * #{$spacing});
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}

h1,
.h1 {
  font-size: responsive 30px 36px;
  @include letter-spacing(-0.3);
  font-weight: 300;
}

h2,
.h2 {
  font-size: responsive 30px 36px;
  line-height: responsive 40px 46px;
  @include letter-spacing(-0.3);
  font-weight: 300;
  @apply mb-8;
}

h3,
.h3 {
  font-size: responsive 20px 30px;
  line-height: responsive 30px 36px;
  @include letter-spacing(-0.3);
}

h4,
.h4 {
  font-size: 16px;
  @include letter-spacing(-0.3);
}

h5,
.h5 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

h6,
.h6 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

p,
.p {
  font-size: 16px;
  line-height: 1.64;
  orphans: 2;
  @include letter-spacing(-0.3);
  margin-bottom: 1.6em;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.text-xs {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

img.lazyload:not([src]) {
  visibility: hidden;
}

.wysiwyg {
  p {
    line-height: 2em;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

a:not([class]) {
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
}
.main-header {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  @media (max-width: 764px) {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
.headroom--not-top {
  background: rgba(64, 75, 105, 0.8);
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  .mt-7 {
    margin-top: 0.75rem !important;
  }
  .mt-6 {
    margin-top: 0.75rem !important;
  }
  .logo-wrap {
    max-width: 122px !important;
    @media (max-width: 764px) {
      max-width: 100px !important;
    }
  }
}
.main-footer a {
  white-space: nowrap;
}

.province-select {
  overflow: hidden;
  .flickity-viewport {
    overflow: visible;
  }
}
.search-form {
  input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid white;
    padding: 10px 30px;
    margin-right: 10px;
    vertical-align: bottom;
  }
  button {
    background: transparent;
    color: white;
    border-radius: 50%;
    padding: 14px;
    border: 1px solid white;
    vertical-align: bottom;
  }
}
.slide-img {
  svg,
  img {
    max-width: 100%;
    max-height: 450px;
    @media (max-width: 1000px) {
      max-width: 400px;
      margin: 0 auto;
    }
    @media (max-width: 450px) {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}

.button.active {
  color: #f2e4b3 !important;
}
.svg-include {
  svg {
    width: 120%;
    @media (max-width: 680px) {
      width: 100%;
    }
  }
  img {
    width: 120%;
    max-width: 120%;
    @media (max-width: 680px) {
      width: 100%;
      max-width: 100%;
    }
  }
}
.mob-asset {
  @media (max-width: 680px) {
    width: 80%;
    width: 80%;
    margin: 0 auto;
    max-width: 290px;
  }
}
#awareness-svg {
  .st0 {
    fill: #404b69;
  }
  .st1 {
    fill: #303c58;
  }
  .st2 {
    fill: #cfdfd7;
  }
  .st3 {
    fill: #263238;
  }
  .st4 {
    fill: #37474f;
  }
  .st5 {
    fill: #6e7da1;
  }
  .st6 {
    fill: #f7dae0;
  }
  .st7 {
    fill: #cad2eb;
  }
  .st8 {
    fill: #f1e3b2;
  }
  .st9 {
    fill: #f3d0b3;
  }
  .st10 {
    fill: #3f4b69;
  }
  .st11 {
    fill: #a1aea9;
  }
  .st12 {
    fill: #e4a9b9;
  }
  .st13 {
    fill: #9ea5b8;
  }
  .st14 {
    fill: #e9dcac;
  }
  .st15 {
    fill: #9c9892;
  }
  .st16 {
    fill: #cfb29b;
  }
  .st17 {
    fill: #938f8a;
  }
  .st18 {
    fill: #f7a9a0;
  }
  .st19 {
    fill: #df9b93;
  }
  .st20 {
    opacity: 0.1;
    enable-background: new;
  }
  .st21 {
    fill: #ffffff;
  }
  .st22 {
    fill: #ad6359;
  }
  .st23 {
    opacity: 0.3;
    fill: #ffffff;
    enable-background: new;
  }
  .st24 {
    opacity: 0.1;
    fill: #050400;
    enable-background: new;
  }
  .st25 {
    opacity: 0.5;
    fill: #ffffff;
    enable-background: new;
  }
  .st26 {
    fill: #ff98b9;
  }
  .st27 {
    opacity: 0.2;
    fill: #263238;
    enable-background: new;
  }
  .st28 {
    fill: #ffc727;
  }
  .st29 {
    opacity: 0.2;
    fill: #ffffff;
    enable-background: new;
  }
  .st30 {
    fill: #475475;
  }
  .st31 {
    fill: #ff9abb;
  }
  .st32 {
    fill: #c5beb6;
  }
}
.site-logo {
  width: 100%;
  path {
    fill: #f2e4b3;
  }
  polygon {
    fill: #f2e4b3;
  }
  circle {
    fill: #f2e4b3;
  }
}
#get-involved {
  .st0 {
    fill: #404b69;
  }
  .st1 {
    fill: #303c58;
  }
  .st2 {
    fill: #ebebeb;
  }
  .st3 {
    fill: #263238;
  }
  .st4 {
    fill: #cfe0d8;
  }
  .st5 {
    fill: #ad6359;
  }
  .st6 {
    opacity: 0.2;
  }
  .st7 {
    fill: #37474f;
  }
  .st8 {
    fill: #f8dbe0;
  }
  .st9 {
    fill: #f2e4b3;
  }
  .st10 {
    fill: #ff99ba;
  }
  .st11 {
    fill: #cfdfd7;
  }
  .st12 {
    fill: #a1aea9;
  }
  .st13 {
    fill: #e9dcac;
  }
  .st14 {
    fill: #c5beb6;
  }
}
.logo-colour {
  fill: #f2e4b3;
}

.header-slider {
  padding-top: 190px;
  @media (max-width: 640px) {
    padding-top: 80px;
  }
}
.full-text,
.text-col {
  h2,
  h3 {
    font-family: "Didot";
  }
  h3 {
    margin-bottom: 20px;
  }
}
.testimonial {
  p {
    font-family: "Didot";
    font-size: responsive 30px 36px;
    line-height: responsive 40px 46px;
    @include letter-spacing(-0.3);
    font-weight: 300;
    @apply mb-8;
    @apply text-navy;
  }
}
.abstract {
  a {
    text-decoration: none;
  }
  ul {
    list-style: inherit;
    margin-left: 20px;
  }
}

section.bg-navy.py-48.bg-cont {
  padding-bottom: 330px !important;
}
.bg-white,
.bg-grey {
  .text-col {
    @apply text-navy;
  }
  .text-purple {
    color: #475374 !important;
  }
}
.bg-navy,
.bg-darkPurple {
  .text-col {
    @apply text-white;
  }
  .span-word {
    @apply text-yellow;
  }
}
.bg-grey {
  @apply bg-grey-light;
}
.bg-cont.bg-white {
  background: #404b69 !important;
  padding: 0px !important;
  @media (min-width: 1000px) {
    padding: 50px !important;
  }
  .white-insert {
    background-color: white;
    padding: 60px 0;
    padding-bottom: 150px;
    .text-white {
      @apply text-navy;
    }
    @media (min-width: 1000px) {
      padding: 170px 0;
    }
  }
}
.span-word {
  @apply text-gold;
  img {
    position: absolute;
    top: -140px;
    right: 0;
    width: 80%;
  }
}
.bg-navy {
  .span-word {
    @apply text-green;
    img {
      position: absolute;
      top: -100px;
      right: 0;
      width: 100%;
    }
  }
}
main {
  overflow: hidden;
}
.bg-white,
.bg-grey {
  a.bg-white {
    background-color: #cbd3ec !important;
  }
  a.bg-white:hover {
    background-color: #475374 !important;
    border-color: #475374 !important;
    color: white !important;
  }
  .border-white {
    border-color: #475374 !important;
    color: #475374 !important;
  }
}
#payments {
  .st0 {
    fill: #dbd8d8;
  }
  .st1 {
    clip-path: url(#SVGID_00000043448945425870052860000014690333135836736435_);
  }
  .st2 {
    clip-path: url(#SVGID_00000103223985293335707950000016526620829391611566_);
  }
  .st3 {
    fill: #c6bfb7;
  }
  .st4 {
    fill: #f2e4b3;
  }
  .st5 {
    fill: #ffffff;
  }
  .st6 {
    fill: #e5abba;
  }
  .st7 {
    fill: #cfe0d8;
  }
  .st8 {
    fill: #c7c7c7;
  }
  .st9 {
    fill: #ad6359;
  }
  .st10 {
    fill: #263238;
  }
  .st11 {
    clip-path: url(#SVGID_00000060016904437941394170000010443051247749173135_);
  }
  .st12 {
    fill: #9d9993;
  }
  .st13 {
    fill: #6e7ca1;
  }
  .st14 {
    fill: #cbd3ec;
  }
  .st15 {
    fill: #f8f8f8;
  }
  .st16 {
    fill: #37474f;
  }
}

#running {
  .st0 {
    fill: none;
  }
  .st1 {
    clip-path: url("#SVGID_00000059304690852445510160000011651214052609897856_");
  }
  .st2 {
    fill: #ebebeb;
  }
  .st3 {
    fill: #263238;
  }
  .st4 {
    fill: #cfe0d8;
  }
  .st5 {
    fill: #ad6359;
  }
  .st6 {
    opacity: 0.2;
  }
  .st7 {
    clip-path: url("#SVGID_00000039118265256969127390000011623196418514854325_");
  }
  .st8 {
    fill: #37474f;
  }
  .st9 {
    fill: #f8dbe0;
  }
  .st10 {
    clip-path: url("#SVGID_00000098919782660380048360000000158691755510065560_");
  }
  .st11 {
    fill: #f2e4b3;
  }
  .st12 {
    fill: #ff99ba;
  }
}
#home {
  .st0 {
    fill: #303d59;
  }
  .st1 {
    fill: #c5beb6;
  }
  .st2 {
    fill: #a1aea9;
  }
  .st3 {
    fill: #cfdfd7;
  }
  .st4 {
    fill: #475473;
  }
  .st5 {
    fill: #c7c7c7;
  }
  .st6 {
    fill: #a6a6a6;
  }
  .st7 {
    fill: #54617d;
  }
  .st8 {
    fill: #6e7da1;
  }
  .st9 {
    fill: #f7a8a1;
  }
  .st10 {
    fill: #263338;
  }
  .st11 {
    fill: #2b364f;
  }
  .st12 {
    fill: #637091;
  }
  .st13 {
    fill: #de998f;
  }
  .st14 {
    fill: #ad6359;
  }
  .st15 {
    fill: #f2e3b2;
  }
  .st16 {
    fill: #cfe0d8;
  }
  .st17 {
    fill: #eb9482;
  }
  .st18 {
    fill: #e5abba;
  }
  .st19 {
    fill: #455963;
  }
  .st20 {
    fill: #f7dbe0;
  }
  .st21 {
    fill: #404a69;
  }
  .st22 {
    fill: #ffffff;
  }
  .st23 {
    fill: #38474f;
  }
  .st24 {
    fill: #ff99ba;
  }
  .st25 {
    fill: #dbdbdb;
  }
  .st26 {
    fill: #d4bdbf;
  }
  .st27 {
    fill: #cfe0d9;
  }
  .st28 {
    fill: #e4a9b9;
  }
  .st29 {
    fill: #263238;
  }
  .st30 {
    fill: #d3766a;
  }
  .st31 {
    fill: #f7dae0;
  }
  .st32 {
    fill: #f3d0b3;
  }
  .st33 {
    fill: #455a64;
  }
  .st34 {
    opacity: 0.1;
    enable-background: new;
  }
  .st35 {
    fill: #ff9bbc;
  }
}
.mega-nav.open {
  opacity: 1;
  visibility: visible;
  overflow: scroll;
}
.mega-nav {
  opacity: 0;
  visibility: hidden;
  h3 {
    padding-bottom: 20px;
    span {
      @apply text-purple;
    }
  }
  a {
    text-decoration: none;
  }
  ul {
    li {
      position: relative;
      padding-left: 15px;
      color: white;
      &::after {
        content: "+";
        position: absolute;
        left: 0;
        top: 0px;
        color: white;
      }
      a {
        color: white;
      }
    }
  }
  p {
    color: white;
  }
}
.nav-grid {
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 30px;
  }
}

.div1 {
  grid-area: 1 / 1 / 2 / 4;
}
.div2 {
  grid-area: 2 / 1 / 3 / 2;
}
.div3 {
  grid-area: 2 / 2 / 3 / 3;
}
.div4 {
  grid-area: 2 / 3 / 3 / 4;
}
.div5 {
  grid-area: 3 / 1 / 4 / 2;
}
.div6 {
  grid-area: 3 / 2 / 4 / 3;
}
.div7 {
  grid-area: 3 / 3 / 4 / 4;
}
.div8 {
  grid-area: 4 / 1 / 5 / 4;
}
#monthly-form {
  display: none;
}
#monthly-form.open {
  display: flex;
}
#donate-form {
  display: none;
}
#donate-form.open {
  display: flex;
}
.js-donation-value {
  background: transparent;
  border-bottom: 1px solid;
  @apply border-navy;
}
.team-svg {
  .st0 {
    fill: #3f4b69;
  }
  .st1 {
    fill: #f7a9a0;
  }
  .st2 {
    fill: #cbb484;
  }
  .st3 {
    fill: #37474f;
  }
  .st4 {
    fill: #cfdfd7;
  }
  .st5 {
    opacity: 0.1;
    enable-background: new;
  }
  .st6 {
    fill: #a1aea9;
  }
  .st7 {
    fill: #3a435f;
  }
  .st8 {
    fill: #404b69;
  }
  .st9 {
    fill: #e4a9b9;
  }
  .st10 {
    fill: #f7dae0;
  }
  .st11 {
    fill: #938f8a;
  }
  .st12 {
    fill: #ff9abb;
  }
  .st13 {
    fill: #263238;
  }
  .st14 {
    fill: #d39dac;
  }
  .st15 {
    fill: #a18570;
  }
  .st16 {
    fill: #104944;
  }
  .st17 {
    fill: none;
    stroke: #263238;
    stroke-width: 1.5;
    stroke-miterlimit: 10;
  }
  .st18 {
    fill: #e9dcac;
  }
  .st19 {
    fill: #111418;
  }
  .st20 {
    fill: #beac9b;
  }
  .st21 {
    fill: #f1e3b2;
  }
  .st22 {
    fill: #6e7da1;
  }
  .st23 {
    fill: #c7c7c7;
  }
  .st24 {
    fill: #ffffff;
  }
}
#information-svg {
  .st0 {
    opacity: 0.26;
    fill: #303c58;
    enable-background: new;
  }
  .st1 {
    fill: #303c58;
  }
  .st2 {
    fill: #f7dae0;
  }
  .st3 {
    fill: #263238;
  }
  .st4 {
    fill: #dbdbdb;
  }
  .st5 {
    fill: #a6a6a6;
  }
  .st6 {
    fill: #455a64;
  }
  .st7 {
    fill: #e4a9b9;
  }
  .st8 {
    fill: #ffc727;
  }
  .st9 {
    fill: #cad2eb;
  }
  .st10 {
    fill: #d3766a;
  }
  .st11 {
    fill: #ebebeb;
  }
  .st12 {
    fill: #37474f;
  }
  .st13 {
    fill: #cfe0d8;
  }
  .st14 {
    opacity: 0.2;
    fill: #050504;
    enable-background: new;
  }
  .st15 {
    opacity: 0.2;
    fill: #030402;
    enable-background: new;
  }
  .st16 {
    fill: #ff99ba;
  }
  .st17 {
    fill: #859499;
  }
  .st18 {
    fill: #c7c7c7;
  }
  .st19 {
    fill: #ffc4c0;
  }
  .st20 {
    fill: #8fa9cc;
  }
  .st21 {
    fill: #afbbc8;
  }
  .st22 {
    opacity: 0.15;
    fill: #8fa9cc;
    enable-background: new;
  }
  .st23 {
    fill: #7799c2;
  }
  .st24 {
    fill: #6e8eb3;
  }
  .st25 {
    fill: #a1aea9;
  }
  .st26 {
    opacity: 0.1;
    enable-background: new;
  }
  .st27 {
    fill: #fe00fd;
  }
  .st28 {
    fill: #a8b3c6;
  }
}
.accordion__content {
  max-height: 0em;
  transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87);
}
input[name="panel"]:checked ~ .accordion__content {
  /* Get this as close to what height you expect */
  max-height: 150em;
  z-index: 100;
  left: 0;
}

input[name="panel"] ~ label {
  transition: ease-in-out 2s;
  transition-duration: 300ms;
  position: relative;
  &::before {
    content: "Read more";
    @apply text-green;
  }
}

input[name="panel"]:checked ~ label {
  transition: ease-in-out 2s;
  transition-duration: 200ms;
  position: relative;
  &::before {
    content: "Read less";
    @apply text-yellow;
  }
}
#travel-svg {
  .st0 {
    opacity: 0.75;
    fill: #404b69;
    enable-background: new;
  }
  .st1 {
    enable-background: new;
  }
  .st2 {
    fill: #303c58;
  }
  .st3 {
    fill: #3f4b69;
  }
  .st4 {
    fill: #263238;
  }
  .st5 {
    opacity: 0.5;
  }
  .st6 {
    opacity: 0.2;
    fill: #263238;
    enable-background: new;
  }
  .st7 {
    opacity: 0.1;
    fill: #020100;
    enable-background: new;
  }
  .st8 {
    fill: #ffc727;
  }
  .st9 {
    fill: #cfdfd7;
  }
  .st10 {
    opacity: 0.1;
    enable-background: new;
  }
  .st11 {
    fill: #455a64;
  }
  .st12 {
    fill: #f7a9a0;
  }
  .st13 {
    fill: #a1aea9;
  }
  .st14 {
    fill: #cfe0d8;
  }
  .st15 {
    fill: #ff9cbd;
  }
  .st16 {
    fill: #f7dae0;
  }
  .st17 {
    opacity: 0.1;
  }
  .st18 {
    fill: #020100;
  }
}
#practical-info {
  .st0 {
    fill: #313c58;
  }
  .st1 {
    clip-path: url(#SVGID_00000003082967704018587130000011439899695760846984_);
  }
  .st2 {
    clip-path: url(#SVGID_00000164500594710876955400000003702845319408524459_);
  }
  .st3 {
    fill: #c393a0;
  }
  .st4 {
    fill: #e5abba;
  }
  .st5 {
    fill: #dbdbdb;
  }
  .st6 {
    fill: #8d6c75;
  }
  .st7 {
    fill: #c7c7c7;
  }
  .st8 {
    fill: #a6a6a6;
  }
  .st9 {
    fill: #f7a9a0;
  }
  .st10 {
    fill: #cfe0d8;
  }
  .st11 {
    fill: #263238;
  }
  .st12 {
    fill: #f8dbe0;
  }
  .st13 {
    fill: #cbd3ec;
  }
  .st14 {
    opacity: 0.1;
  }
  .st15 {
    clip-path: url(#SVGID_00000000916734227516584070000000519169224543571127_);
  }
  .st16 {
    clip-path: url(#SVGID_00000168093623488558089310000011093464249809134000_);
  }
  .st17 {
    fill: #030402;
  }
  .st18 {
    fill: #ff9bbc;
  }
  .st19 {
    fill: #ffffff;
  }
  .st20 {
    fill: #37474f;
  }
  .st21 {
    fill: #ad6359;
  }
  .st22 {
    fill: #6e7ca1;
  }
}
.mc-field-group {
  label {
    font-size: 20px;
    margin-top: 20px;
    margin-top: 20px;
    display: inline-block;
    span {
      margin-left: 10px;
    }
  }
  p {
    margin-bottom: 20px;
  }
}
